import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterBannerButtonLarge } from "@components/BuilderWrapperComponents/BannerButtonLarge";
import { RegisterCtaButtonPlayVideo } from "@components/BuilderWrapperComponents/CtaButtonPlayVideo";
import { RegisterEmailCaptureLeadsWrapper } from "@components/BuilderWrapperComponents/EmailCaptureLeadsWrapper";
import { RegisterEmailCaptureWBackground } from "@components/BuilderWrapperComponents/EmailCaptureWBackground";
import { RegisterEmailOnlyForm } from "@components/BuilderWrapperComponents/EmailOnlyFormBC";
import { RegisterGlobalBannerSection } from "@components/BuilderWrapperComponents/GlobalBannerSectionBC";
import { RegisterPrimaryBtn } from "@components/BuilderWrapperComponents/PrimaryBtnBC";
import { RegisterPrimaryBtnWithArrow } from "@components/BuilderWrapperComponents/PrimaryBtnWithArrowBC";
import { RegisterResourceGatedDownload } from "@components/BuilderWrapperComponents/ResourceGatedDownload";
import { RegisterResourceSliderWrapper } from "@components/BuilderWrapperComponents/ResourceSliderWrapper";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";
import { RegisterSocialLinksWrapper } from "@components/BuilderWrapperComponents/SocialLinksWrapper";

export const RegisterComponentResourcePost = () => {
  RegisterGlobalBannerSection();
  RegisterEmailCaptureLeadsWrapper();
  RegisterSocialLinksWrapper();
  RegisterPrimaryBtn();
  RegisterPrimaryBtnWithArrow();
  RegisterEmailOnlyForm();
  RegisterBannerButtonLarge();
  RegisterCtaButtonPlayVideo();
  RegisterResourceGatedDownload();
  RegisterEmailCaptureWBackground();
  RegisterPricingPageToolTips();
  RegisterResourceSliderWrapper();
  RegisterSignupFormDrip();
};
